import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchBidders(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/bidders', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        createBidder(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/bidders', payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchBidder(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/bidders/${id}/edit`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        updateBidder(ctx, { id, payload }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/bidders/${id}`, payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        viewCertificate(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/bidders/${id}/view-certificate`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        deleteBidder(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/bidders/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        resendCredentials(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/bidders/${id}/resend-credentials`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
    }
}