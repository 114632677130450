<template>
	<validation-observer ref="refForm">
		<b-form
			@submit.prevent="onSubmit"
			@reset.prevent="resetForm"
		>
			<b-row>
				<b-col md="12">
					<b-card
						footer-tag="footer"
						class="mb-0"
					>
						<b-row>
							<!-- BIDDER NAME -->
							<b-col
								cols="12"
								class="mb-2"
							>
								<label for="name">
									<strong>NOMBRE DEL POSTOR</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									rules="required"
									name="nombre del postor"
								>
									<b-form-input
										id="name"
										v-model="formData.names"
										placeholder="Ingrese la razón social del postor ..."
										:state="errors.length > 0 ? false : null"
										trim
									/>

									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<!-- BUSINESS AREA -->
							<b-col
								cols="12"
								md="6"
								class="mb-2"
							>
								<label for="businessArea">
									<strong>RUBRO</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									rules="required"
									name="rubro"
								>
									<v-select
										id="businessArea"
										v-model="formData.businessArea"
										:state="errors.length > 0 ? false : null"
										:options="options.businessAreas"
										placeholder="Seleccione"
									/>

									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<!-- RUC -->
							<b-col
								cols="12"
								md="3"
								class="mb-2"
							>
								<label for="ruc">
									<strong>RUC</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									rules="required|integer|min:11"
									name="RUC"
								>
									<b-form-input
										id="ruc"
										v-model="formData.ruc"
										placeholder="Ingrese el RUC ..."
										:state="errors.length > 0 ? false : null"
										trim
									/>

									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<!-- PHONE -->
							<b-col
								cols="12"
								md="3"
								class="mb-2"
							>
								<label for="phone">
									<strong>TELÉFONO</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									rules="integer|min:9"
									name="Teléfono"
								>
									<b-form-input
										id="phone"
										v-model="formData.phone"
										:state="errors.length > 0 ? false : null"
										placeholder="Ingrese teléfono ..."
										trim
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<!-- EMAIL -->
							<b-col
								cols="12"
								md="6"
								class="mb-2"
							>
								<label for="email">
									<strong>CORREO ELECTRÓNICO</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									rules="required|email"
									name="correo electrónico"
								>
									<b-form-input
										id="email"
										v-model="formData.email"
										placeholder="Ingrese el correo electrónico ..."
										:state="errors.length > 0 ? false : null"
										trim
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>
						</b-row>

						<!-- EMAIL ALTERNATE-->
						<b-row
							cols="12"
							class="mb-1"
							v-for="(other, index) in other_emails"
							:key="index"
						>
							<b-col
								cols="12"
								v-if="index===0"
							>
								<label for="alternate_email">
									<strong>CORREO ELECTRÓNICO SECUNDARIO</strong>
									<feather-icon
										variant="primary"
										icon="AlertCircleIcon"
										v-b-tooltip.hover.right="'Los correos secundarios solo recibirán notificaciones'"
										class="ml-1"
									/>
								</label>
							</b-col>
							<b-col
								:cols="index === 0 ? '12' : '10'"
								:lg="index === 0 ? '12' : '11'"
							>
								<validation-provider
									#default="{ errors }"
									rules="email"
									name="correo electrónico secundario"
								>
									<b-form-input
										:id="`alternate_email-${other.id}`"
										v-model="other.email"
										placeholder="Ingrese el correo electrónico secundario ..."
										:state="errors.length > 0 ? false : null"
										trim
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>
							<b-col
								cols="2"
								lg="1"
								class="text-center"
							>
								<b-button
									v-if="index !== 0"
									v-ripple.400="'rgba(40, 199, 111, 0.15)'"
									variant="flat-danger"
									class="btn-icon rounded-circle"
									@click="removeSecondaryEmail(index)"
								>
									<feather-icon icon="TrashIcon" />
								</b-button>
							</b-col>
						</b-row>

						<b-button
							v-ripple.400="'rgba(255, 255, 255, 0.15)'"
							variant="outline-primary"
							size="sm"
							@click="addSecondaryEmail()"
						>
							<feather-icon
								icon="PlusIcon"
								class="mr-25"
							/>
							<span>AGREGAR OTRO CORREO</span>
						</b-button>

						<template #footer>
							<!-- BUTTON SUBMIT -->
							<b-button-loading
								text="ACTUALIZAR"
								type="submit"
								:processing="processing"
							></b-button-loading>

							<b-button
								v-if="!processing"
								v-ripple.400="'rgba(186, 191, 199, 0.15)'"
								type="button"
								variant="outline-secondary"
								class="ml-2"
								:to="{ name: 'bidders-list' }"
							>CANCELAR</b-button>
						</template>
					</b-card>
				</b-col>
			</b-row>
		</b-form>
	</validation-observer>
</template>

<script>
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import { ref, onBeforeMount, onUnmounted } from "@vue/composition-api"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import bidderStoreModule from "../../bidderStoreModule"
import { Spanish } from "flatpickr/dist/l10n/es"
import flatPickr from "vue-flatpickr-component"
import { required, email, integer, min } from "@validations"
import useNotifications from "@notifications"
import Ripple from "vue-ripple-directive"
import { v4 as uuidv4 } from "uuid"
import vSelect from "vue-select"
import moment from "moment"
import store from "@/store"
import axios from "@axios"

export default {
	components: {
		ValidationProvider,
		ValidationObserver,

		BButtonLoading,

		flatPickr,
		required,
		email,
		integer,
		min,
		vSelect
	},
	directives: {
		Ripple
	},
	props: {
		bidderData: {
			type: Object,
			required: true
		}
	},
	setup(props) {
		// USE TOAST
		const { toastNotification } = useNotifications()

		// REFS
		const refForm = ref(null)

		const bidderData = ref(props.bidderData)

		const onlyDateConfig = ref({
			enableTime: false,
			locale: Spanish,
			minDate: moment().add(1, "days").format("YYYY-MM-DD")
		})

		const formData = ref({
			names: null,
			phone: null,
			ruc: null,
			email: null,
			/* alternate_email: null, */
			businessArea: null
		})

		const processing = ref(false)
		const options = ref({})
		const other_emails = ref([
			{
				id: uuidv4(),
				email: null
			}
		])

		const BIDDER_APP_STORE_MODULE_NAME = "app-bidder"

		// REGISTER MODULE
		if (!store.hasModule(BIDDER_APP_STORE_MODULE_NAME))
			store.registerModule(BIDDER_APP_STORE_MODULE_NAME, bidderStoreModule)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(BIDDER_APP_STORE_MODULE_NAME))
				store.unregisterModule(BIDDER_APP_STORE_MODULE_NAME)
		})

		// CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
		onBeforeMount(async () => {
			await getSelectFilters()
			setData()
		})

		const getSelectFilters = async () => {
			try {
				const { data } = await axios.get("/selectors/business-areas")
				options.value = data
			} catch (error) {
				toastNotification(error.response.data.message, false)
			}
		}

		const setData = () => {
			const businessArea = options.value.businessAreas.find(
				(item) => item.value === bidderData.value.business_area_id
			)
			const { alternate_emails } = bidderData.value
			if (alternate_emails) {
				other_emails.value = JSON.parse(alternate_emails).map((email) => ({
					id: uuidv4(),
					email
				}))
			}

			formData.value = {
				names: bidderData.value.names,
				ruc: bidderData.value.ruc,
				phone: bidderData.value.phone,
				email: bidderData.value.email,
				/* alternate_email: bidderData.value.alternate_email, */
				businessArea
			}
		}

		const addSecondaryEmail = () => {
			other_emails.value.push({
				id: uuidv4(),
				email: null
			})
		}

		const removeSecondaryEmail = (index) => {
			other_emails.value.splice(index, 1)
		}

		const onSubmit = async () => {
			const isValid = await refForm.value.validate()

			if (!isValid) {
				const message = "Complete los campos requeridos."
				toastNotification(message, false)
				return
			}

			processing.value = true

			const id = bidderData.value.id
			const alternate_emails = other_emails.value
				.filter((item) => item.email)
				.map((item) => item.email)

			const payload = {
				names: formData.value.names,
				ruc: formData.value.ruc,
				business_area_id: formData.value.businessArea.value,
				phone: formData.value.phone,
				email: formData.value.email,
				alternate_emails
			}

			store
				.dispatch("app-bidder/updateBidder", { id, payload })
				.then((response) => {
					formData.value.names = formData.value.names.toUpperCase()
					toastNotification(response.data.message)
				})
				.catch((error) => {
					const message =
						error.response.data.message ||
						"Error al actualizar la información general del postor"
					toastNotification(message, false)
				})
				.finally(() => (processing.value = false))
		}

		return {
			// DATA
			onlyDateConfig,
			processing,
			formData,
			options,

			// REFS
			refForm,
			other_emails,

			// METHODS
			addSecondaryEmail,
			removeSecondaryEmail,
			onSubmit
		}
	}
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>