
<template>
	<component :is="'div'">
		<!-- Alert: No item found -->
		<b-alert
			variant="danger"
			:show="bidderData === undefined"
		>
			<h4 class="alert-heading">Error al obtener los datos del postor</h4>
			<div class="alert-body">
				No se encontró ningún postor con este id. Verificar en la
				<b-link
					class="alert-link"
					:to="{ name: 'bidders-list'}"
				>Lista de postores</b-link>para buscar otro postor.
			</div>
		</b-alert>

		<b-tabs
			v-if="bidderData"
			pills
		>
			<!-- Tab: General Information -->
			<b-tab>
				<template #title>
					<feather-icon
						icon="InfoIcon"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Información general</span>
				</template>

				<bidder-edit-tab-general-information
					class="mt-2 pt-75"
					:bidder-data="bidderData"
				/>
			</b-tab>
		</b-tabs>
	</component>
</template>

<script>
import BidderEditTabGeneralInformation from "./general-information/BidderEditTabGeneralInformation.vue"
import bidderStoreModule from "../bidderStoreModule"
import useNotifications from "@notifications"
import { ref, onUnmounted } from "@vue/composition-api"
import router from "@/router"
import store from "@/store"

export default {
	components: {
		BidderEditTabGeneralInformation
	},
	setup() {
		const { toastNotification } = useNotifications()

		// REFS
		const bidderData = ref(null)

		const BIDDER_APP_STORE_MODULE_NAME = "app-bidder"

		// REGISTER MODULE
		if (!store.hasModule(BIDDER_APP_STORE_MODULE_NAME))
			store.registerModule(BIDDER_APP_STORE_MODULE_NAME, bidderStoreModule)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(BIDDER_APP_STORE_MODULE_NAME))
				store.unregisterModule(BIDDER_APP_STORE_MODULE_NAME)
		})

		store
			.dispatch("app-bidder/fetchBidder", { id: router.currentRoute.params.id })
			.then((response) => {
				if (response.data === "error") {
					bidderData.value = undefined
				} else {
					bidderData.value = response.data.bidder
				}
			})
			.catch((error) => {
				if (error.response.status === 404) {
					bidderData.value = undefined
				}
				toastNotification(error.response.data.message, false)
			})

		return {
			// DATA
			bidderData
		}
	}
}
</script>